.experience {
    margin-top: 150px;
    font-family: 'Chillax';
}

.timeline-date-white {
    color: white;
}
.timeline-date-black {
    color: black;
}